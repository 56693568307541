.childavatar{
    background-color:#fff5e9;border-color:#ffe0bd;
    color: #FF0000;
    position:relative;
    min-width:20px;
    height:20px;
    border-radius:100%;
    overflow:hidden;
    margin-right:10px;
    display:flex;
    align-items:center;justify-content:center;
    font-size:14px;
    font-weight:bold;
    padding: 2px;
}

.textAreaEditClass{
    padding: 0.2rem;
    border: 1px solid #ccc;
    margin-right:0.5rem;
    font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
    font-size:12px;
}
.textAreaComment{
    padding: 0.2rem;
    border:16px;
    font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
    font-size:12px;
}
.textAreaReply{
    padding: 0.2rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
    font-size:12px;
}
.flexBoxDiv{
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}
.commentContainer {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px;
    max-width: 600px;
    margin: 16px auto;
    background-color: white;
    color: #333;
    margin-left: 1rem;
  }
  
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
 
  .userInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  
  .userName {
    font-weight: bold;
    font-size: 11px;
    color: #333;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .timestamp {
    font-weight: normal;
    font-size: 11px;
    color: #888;
    margin-left: 8px;
  }
  
  .commentText {
    font-size: 11px;
    margin-bottom: 8px;
    display: flex;
  }
  
  .timestampText {
    color: #3b82f6;
  }
  
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .actionButton {
    color: #888;
    font-size: 2px;
  }
  
  .replyLink {
    color: #3b82f6;
    text-decoration: none;
    font-size: 11px;
    padding-top: 2px;
    padding-left: 4px;
  }
  
  .readStatus {
    font-size: 11px;
    color: #888;
  }
  
  .maindiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #F2F3F3;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    column-gap: 0.3rem;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    width: fit-content;
    height: fit-content;
  }
  
