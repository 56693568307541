.commentsAvatar{
  background-color:black;
  border-color:#ffe0bd;
  color: white;
  position:relative;
  min-width:30px;
  height:30px;
  border-radius:100%;
  overflow:hidden;
  margin-right:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:16px;
  font-weight:bold;
  padding: 2px;
}
.commenttextAreaEdit{
  padding: 0.2rem;
  border: 1px solid #ccc;
  margin-right:0.5rem;
  font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
}
.commentTextArea{
  padding: 0.2rem;
  border:16px;
  font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
}
.commentTextAreaReply{
  padding: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-family: “Arial Rounded MT Bold”, “Helvetica Rounded”, Arial, sans-serif;
}

/* .animated-border-box {
  position: relative;
  padding: 1rem;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.animated-border-box-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: white;
  background-size: 400%;
  animation: border-glow 10s linear infinite;
  z-index: 1;
  border-radius: 8px;
  filter: blur(8px);
}

@keyframes border-glow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */
